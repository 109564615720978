import styled from 'styled-components';
import Img from 'gatsby-image';
import colors from '../../constants/colors';
import dimensions from '../../constants/dimensions';
import breakpoints from '../../constants/breakpoints';

// wrapper for the gab logo
export const GabLogoWrapper = styled.div`
  z-index: 3;
  position: absolute;
  margin: 1rem;
  bottom: 0;
  left: 0;

  @media (max-width: ${breakpoints.medium}px) {
    display: none;
  }
`;

// image component for the gab logo
export const GabLogo = styled(Img)`
  height: 110px;
  width: 100px;
  
  
`;

// wrapper for the vcp logo
export const VcpLogo = styled.div`
  z-index: 3;
  width: 250px;
  position: absolute;
  margin: 1rem;
  bottom: 0;
  right: 0;
  
   @media (max-width: ${breakpoints.medium}px) {
    display: none;
  }
`;

// "Mehr Information" Button
export const MoreInformation = styled.div`
  background-color: ${colors.vcp};
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
  margin: 4px 2px;
  cursor: pointer;
`;

export const Map = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5vh auto 3vh auto;
  max-width: ${dimensions.maxWidth}px;
  margin-top: 5vh;
  align-items: center;
  max-width: ${dimensions.maxWidth}px;

  h2 {
    margin: 0 1rem 0 1rem;
  }
`;
