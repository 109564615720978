// imports
import { css, keyframes } from 'styled-components';

/* css animations */
/* Heros switch */
export function heroOneOpacity(options = {}) {
  const config = { ...{ delay: 0 }, ...options };
  const { delay } = config;
  const animation = keyframes`
    0% {
        opacity: 0;

    }
    100% {
        opacity: 0.5;

    }
  `;
  return css`
    animation-name: ${animation};
    animation-delay: ${delay}ms;
    animation-duration: 5000ms;
    animation-direction: alternate;
    animation-iteration-count: infinite;
  `;
}

export function heroTwoOpacity(options = {}) {
  const config = { ...{ delay: 0 }, ...options };
  const { delay } = config;
  const animation = keyframes`
    0% {
        opacity: 0;

    }
    100% {
        opacity: 0.5;

    }
  `;
  return css`
    animation-name: ${animation};
    animation-delay: ${delay}ms;
    animation-duration: 5000ms;
    animation-direction: alternate-reverse;
    animation-iteration-count: infinite;
  `;
}

// GAB logo slide left
export function gabLeft(options = {}) {
  const config = { ...{ delay: 0 }, ...options };
  const { delay } = config;
  const animation = keyframes`
    0% {
        transform: translate(0, 0);

    }
    100% {
        transform: translate(-49vW, 29vH);

    }
  `;
  return css`
    animation-name: ${animation};
    animation-delay: ${delay}ms;
    animation-duration: 3000ms;
    animation-timing-function: ease-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  `;
}

// vcp logo slide right
export function vcpRight(options = {}) {
  const config = { ...{ delay: 0 }, ...options };
  const { delay } = config;
  const animation = keyframes`
    0% {
        transform: translate(0, 0);

    }
    100% {
        transform: translate(27vW, 15vH);
        scrollToComponent(this.input);

    }
  `;
  return css`
    animation-name: ${animation};
    animation-delay: ${delay}ms;
    animation-duration: 3000ms;
    animation-timing-function: ease-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  `;
}


/* Heros switch */
export function arrowJump(options = {}) {
  const config = { ...{ delay: 0 }, ...options };
  const { delay } = config;
  const animation = keyframes`
    0% {
        transform: translate(0, 0);

    }
    100% {
        transform: translate(0, -10px);

    }
  `;
  return css`
    animation-name: ${animation};
    animation-delay: ${delay}ms;
    animation-duration: 500ms;
    animation-direction: alternate;
    animation-iteration-count: infinite;
  `;
}