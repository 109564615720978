// imports
import styled from "styled-components"
import { arrowJump } from "../../animation/animation"
import dimensions from "../../constants/dimensions"
import Img from "gatsby-image"

/* styled components for the home page */

export const HeroBackgroundImage = styled(Img)`
  height: calc(100vh - ${dimensions.headerHeight}px);
  position: absolute;

  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
`

export const HeroText = styled.div`
  width: 100%;
  height: calc(100vh - ${dimensions.headerHeight}px);
  position: absolute;
  top: ${dimensions.headerHeight}px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    margin: 0;

    font-size: 2.4rem;
    text-align: center;
  }

  h4 {
    margin: 0;

    font-size: 1.2rem;
    text-align: center;
  }

  .imageContainer {
    position: absolute;
    bottom: 10px;
    padding: 0;

    background-color: transparent;
    border-style: none;
  }

  img {
    width: 30px;

    ${arrowJump()};
  }
`

export const Wrapper = styled.div`
  opacity: 0.5;
`

/*
@font-face {
  font-family: "Formal Script 421 BT";
 src: url(formalScript.ttf);
}
font-family: "Formal Script 421 BT", arial;

*/
