// imports
import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/Layout"
import GabMap from "../components/Map"
import {
  HeroBackgroundImage,
  HeroText,
  Wrapper,
} from "../components/Hero/styled"
import { Pic, PicText, Text } from "../components/TextComponents/styled"
import {
  GabLogo,
  GabLogoWrapper,
  Map,
  MoreInformation,
  VcpLogo,
} from "../components/home/styled"
import styled from "styled-components"
import "../animation/myAnimations.css"
import "../animation/animate.css"
import LazyLoad from "react-lazyload"
import dimensions from "../constants/dimensions"

// constants
const Content = styled.div`
  background-color: white;
  height: 100%;
  width: 100%;
  z-index: 3;
`
const arrowDown = require("../images/arrow-down.png")

// Home Page
export default function HomePage({ data, location }) {
  // triggered when clicked on the arrow pointing downwards in the hero image
  // scrolls past the hero image
  const scrollDown = () => {
    window.scrollTo({
      top: window.innerHeight - dimensions.headerHeight,
      behavior: "smooth",
    })
  }

  const hero = data.pinte.childImageSharp.fluid

  // render

  return (
    <Layout
      title="Gau Alt-Burgund | Startseite"
      description="Website des Gau Alt-Burgunds im Verein Christlicher Pfadfinderinnen und Pfadfinder (VCP)"
      location={location}
    >
      {/* hero part */}

      <Wrapper>
        <HeroBackgroundImage fluid={hero} />
      </Wrapper>

      <HeroText>
        <h2>Gau Alt-Burgund</h2>
        <h4>im Verband Christlicher Pfadfinderinnen und Pfadfinder</h4>
        <button className={"imageContainer"} onClick={scrollDown}>
          <img src={arrowDown} alt="nach unten zeigender Pfeil" />
        </button>
      </HeroText>

      {/* logo animation */}
      <GabLogoWrapper>
        <GabLogo
          fluid={data.gab_logo.childImageSharp.fluid}
          alt="Logo des Gau Alt-Burgunds"
        />
      </GabLogoWrapper>

      <VcpLogo>
        <Img
          fluid={data.vcp_logo.childImageSharp.fluid}
          alt="Logo des VCP"
        />
      </VcpLogo>

      <Content>
        {/* short description */}
        <PicText>
          <Pic imageWidth={100}>
            <Img
              fluid={data.gab_logo.childImageSharp.fluid}
              alt="Logo des Gau Alt-Burgunds"
              // style={{width: "100px", height: "100px"}}
            />
          </Pic>
          <Text>
            <h2>Herzlich Willkommen..</h2>
            <p>
              ... auf unserer neuen Gau-Homepage! Wir sind der Gau Alt-Burgund,
              die Pfadfinder*innen aus Rheinhessen und der Pfalz! Hier erfahrt
              ihr alles, was es über unseren Gau und uns Pfadfinder*innen zu
              wissen gibt.
            </p>
            <Link to="/ueber-uns">
              <MoreInformation>Mehr lesen</MoreInformation>
            </Link>
          </Text>
        </PicText>

        {/* Map */}
        <LazyLoad height={200}>
          <Map>
            <h2>Stämme und Siedlungen im Gau Alt-Burgund</h2>
            <GabMap />
          </Map>
        </LazyLoad>
      </Content>
    </Layout>
  )
}

// graph for hero images
export const HeroImage = graphql`
  fragment HeroImage on File {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

// graph for story images
export const StoryImage = graphql`
  fragment TextImage on File {
    childImageSharp {
      fluid(maxWidth: 1024) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

// queries
export const query = graphql`
  query {
    wald: file(relativePath: { eq: "wald.jpg" }) {
      ...HeroImage
    }

    pinte: file(relativePath: { eq: "pinte.jpg" }) {
      ...HeroImage
    }

    gab_logo: file(relativePath: { eq: "gab-logo.png" }) {
      ...TextImage
    }

    vcp_logo: file(relativePath: { eq: "logo_vcp.png" }) {
      ...TextImage
    }
  }
`
