import styled from "styled-components"
import breakpoints from "../../constants/breakpoints"
import dimensions from "../../constants/dimensions"

export const TextPic = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem auto 0rem auto;
  max-width: ${dimensions.maxWidth}px;

  @media (min-width: ${breakpoints.smallmedium}px) {
    flex-direction: row;
  }

  @media (min-width: ${breakpoints.large}px) {
    flex-direction: row;
  }
`

export const PicText = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 0 auto;
  max-width: ${dimensions.maxWidth}px;

  @media (min-width: ${breakpoints.smallmedium}px) {
    flex-direction: row;
    justify-content: flex-start;
  }

  @media (min-width: ${breakpoints.large}px) {
    flex-direction: row;
  }
`

export const Text = styled.div`
  margin: 0 2rem 0 2rem;
  width: 100%;

  a, span {
    font-size: 0.8rem;
  }

  @media (max-width: ${breakpoints.smallmedium}px) {
    width: 90vw;
  }
`

export const Pic = styled.div`
  margin: 0 2rem 2rem;
  width: 200px;

  h3 {
    width: 200px;
  }

  @media (max-width: ${breakpoints.smallmedium}px) {
    width: 100px;
    align-self: flex-start;
    margin-left: calc((100vw - ${props => props.imageWidth}px) / 2);
    margin-top: 2rem;
  }
`

export const PicWrapper = styled.div`
  width: 240px;

  @media (max-width: ${breakpoints.xxSmall}px) {
    max-width: 80vw;
  }
`

export const BannerWrapper = styled.section`
  width: 100%;
  margin: 2rem auto 0rem auto;
  // max-width: ${dimensions.maxWidthXL}px;
`

export const LoeweWrapper = styled.div`
  min-width: 8rem;

  img {
    width: 100px;
  }
`
